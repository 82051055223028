import Header from "../Header/Header";
import "./Menu.css";
//import borderMenu from "./Mimages/Meny Border_Colored.webp";
import orangeC from "./Mimages/Edited food/Orange Chicken.webp";
//import beefBroc from "./Mimages/Phoenix Dragon (2).jpg";
//import shrpFR from "./Mimages/Edited food/Shrimp Fried Rice.webp";
//import teriC from "./Mimages/Edited food/Teriyaki Chicken.webp";
import vegRoll from "./Mimages/Edited food/Vegetable Roll.webp";
import pupu from "./Mimages/Edited food/Pu Pu Platter for 2.webp";
//import potS from "./Mimages/PotStickers.jpg";
//import crabP from "./Mimages/crabpuffs.jpg";
//import ed from "./Mimages/EggDrop.jpg";
//import friedRS from "./Mimages/friedRiceShrimp.jpg";
import hns from "./Mimages/Edited food/Hot n Sour Soup.webp";
//import generalC from "./Mimages/Edited food/General Tso Chicken.webp";
import ChickenPanFried from "./Mimages/Edited food/Chicken Pan Fried Noodles.webp";
//import SweetandSourChicken from "./Mimages/Edited food/Sweet and Sour Chicken.webp";
import CashewChicken from "./Mimages/Edited food/Cashew Chicken.webp";
import KungPaoBeef from "./Mimages/Edited food/Kung Pao Beef.webp";
import ChickenLoMein from "./Mimages/Edited food/Chicken Lomein.webp";
import KidsSweetSourChicken from "./Mimages/Edited food/Kids Sweet n Sour Chicken.webp";
import BeefChowFun from "./Mimages/Edited food/Beef Chowfun.webp";
//import GeneralTsoChicken from "./Mimages/Edited food/General Tso Chicken.webp";
import PhoenixDragon from "./Mimages/Edited food/Phoenix Dragon.webp";
import ChickenFriedRice from "./Mimages/Edited food/Chicken Fried Rice.webp";
import CrystalShrimp from "./Mimages/Edited food/Crystal Shrimp.webp";
import HunanChicken from "./Mimages/Edited food/Hunan Chicken.webp";
import MongolianBeef from "./Mimages/Edited food/Mongolian Beef.webp";
import ChickenChowMein from "./Mimages/Edited food/Chicken Chow Mein.webp";
//import InsertPhoto from "./Mimages/Edited food/FoodBorder_Template.webp";
import SweetandSourShrimp from "./Mimages/Edited food/Sweet and Sour Shrimp.webp";
import MooShuPork from "./Mimages/Edited food/MooShu Pork.webp";
//import ShrimpVeg from "./Mimages/Edited food/Shrimp Veg.webp";
import SteamedChickenBroc from "./Mimages/Edited food/Steamed Chicken Broccoli.webp";
import TofuVeg from "./Mimages/Edited food/Tofu Veg.webp";
import WontonChips from "./Mimages/Edited food/Wonton Chips.webp";
import LS_3WD from "./Mimages/Edited food/LS_3WD.webp";
import LS_Chicken_Lomein from "./Mimages/Edited food/LS_Chicken Lomein.webp";
import LS_Mongolian_Beef from "./Mimages/Edited food/LS_Mongolian Beef.webp";
import LS_Orange_Chicken from "./Mimages/Edited food/LS_Orange Chicken.webp";
import Yushang_Pork from "./Mimages/Edited food/Yushang Pork.webp";

import changeBody from "../../changeBody.js";

import MenuMap from "./MenuMap";
changeBody({ color: "#C9C1BB" });
const Menu = () => {
  return (
    <>
      <Header />
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Lunch Special</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          Served until 3:00PM Daily (Not available for online order)
        </div>
        <div className="menuTextDesciption">
          Included: Egg Roll, Crab Puff, Choice of Egg Drop Soup or Hot and Sour
          Soup, a side of Fried Rice or White Rice
        </div>
        <br />

        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img className="foodImage" src={LS_Orange_Chicken} alt="Lunch Special Orange Chicken" />
              <p>Orange Chicken</p>
            </div>
            <div className="foodContainer">
              <img
                className="foodImage"
                src={LS_Mongolian_Beef}
                alt="Mongolian Beef"
              />
              <p>Mongolian Beef</p>
            </div>
            <div className="foodContainer">
              <img className="foodImage" src={LS_Chicken_Lomein} alt="Lunch Special Chicken Lomein" />
              <p>Chicken Lomein</p>
            </div>
            <div className="foodContainer">
              <img className="foodImage" src={LS_3WD} alt="Three White Delight" />
              <p>Three White Delight</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Lunch Special"></MenuMap>
          </div>
        </div>
      </div>
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">All Soft Drinks</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          In House drinks cannot be made to go
        </div>
        <div className="menuTextDesciption">Hot Tea ($1.50 per person)</div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Appetizers</div>
          <div className="label-line" />
        </div>
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img className="foodImage" src={vegRoll} alt="Vegetable Roll" />
              <p>Vegetable Roll</p>
            </div>
            <div className="foodContainer">
              <img className="foodImage" src={pupu} alt="Pu Pu Platter for 2" />
              <p>Pu Pu Platter for 2</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Appetizers"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Soup</div>
          <div className="label-line" />
        </div>
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img className="foodImage" src={hns} alt="Hot and Sour Soup" />
              <p>Hot & Sour Soup</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Soups"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Fried Rice</div>
          <div className="label-line" />
        </div>
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={ChickenFriedRice}
                alt="Chicken Fried Rice"
              />
              <p>Chicken Fried Rice</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Fried Rice"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Hong Kong Style Pan Fried Noodles</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Golden Egg Noodles and Sauteed in a Wok Until Both Sides are Crispy
          and then Topped with Mound of Assorted Vegetables and Meat with Brown
          Sauce)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={ChickenPanFried}
                alt="Chicken Pan Fried"
              />
              <p>Chicken Pan Fried</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="HK Noodles"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Vegetable</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served with White Rice) (Fried Rice extra 50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
          <div className="foodContainer">
              <img
                className="foodImage"
                src={TofuVeg}
                alt="Fried Bean Curd (Tofu) with Vegetable"
              />
              <p>Tofu with Vegetable</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Vegetable"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Poultry</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served with White Rice) (Fried Rice extra 50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={orangeC}
                alt="Orange Chicken"
              />
              <p>Orange Chicken</p>
            </div>
            <div className="foodContainer">
              <img
                className="foodImage"
                src={CashewChicken}
                alt="Cashew Chicken"
              />
              <p>Cashew Chicken</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Poultry"></MenuMap>
          </div>
        </div>
      </div>
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Beef</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served w. White Rice) (Fried Rice extra .50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={KungPaoBeef}
                alt="Kung Pao Beef"
              />
              <p>Kung Pao Beef</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Beef"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Pork</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served w. White Rice) (Fried Rice extra .50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img className="foodImage" src={Yushang_Pork} alt="Yushang_Pork" />
              <p>Yu Shang Pork</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Pork"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Seafood</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served w. White Rice) (Fried Rice extra .50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={SweetandSourShrimp}
                alt="Sweet and Sour Shrimp"
              />
              <p>Sweet and Sour Shrimp</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Seafood"></MenuMap>
          </div>
        </div>
      </div>
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Moo Shu</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Vegetable and Eggs Wrapped w. Crepes)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={MooShuPork}
                alt="MooShuPork"
              />
              <p>Moo Shu Pork</p>
            </div>
            <div className="smallerMenuTextDescription">
              (Stir fry with a side of wraps and hoison sauce)
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Moo Shu"></MenuMap>
          </div>
        </div>
        <br />
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Weight Watcher Selections</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">(Served w. White Rice)</div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={SteamedChickenBroc}
                alt="Steamed Chicken and Vegetable"
              />
              <p>Steamed Chicken and Veg.</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Weight Watcher Selections"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Kids Meals</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (For Children under 10 years old)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={KidsSweetSourChicken}
                alt="Kids Sweet and Sour Chicken"
              />
              <p>Kids Sweet & Sour Chicken</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Kids Meals"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Lo Mein</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">(Soft Noodles)</div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={ChickenLoMein}
                alt="Chicken Lo Mein"
              />
              <p>Chicken Lo Mein</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Lo Mein"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Chow Mein</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">(Crispy Wonton Chips)</div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={ChickenChowMein}
                alt="Chicken Chow Mein"
              />
              <p>Chicken Chow Mein</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Chow Mein"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Chow Fun</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">(Rice Noodles)</div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={BeefChowFun}
                alt="Beef Show Fun"
              />
              <p>Beef Chow Fun</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Chow Fun"></MenuMap>
          </div>
        </div>
      </div>
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Hong Kong Chef's Special</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          (Served w. White Rice) (Fried Rice extra .50 cents)
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={CrystalShrimp}
                alt="Crystal Shrimp"
              />
              <p>Crystal Shrimp</p>
            </div>
            <div className="foodContainer">
              <img
                className="foodImage"
                src={PhoenixDragon}
                alt="Phoenix Dragon"
              />
              <p>Phoenix Dragon</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="HK Special"></MenuMap>
          </div>
        </div>
      </div>
      <div
        className="bmenu"
        style={{
          backgroundSize: "cover",
          //backgroundImage: `url(${require("./Mimages/Meny Border_Colored.png")})`,
        }}
      >
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Special Family Dinner</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption">
          $17.95 PER PERSON (MINIMUM OF TWO)
          <br />
          Appetizers: Egg Roll, Crab Puff and Fried Shrimp
          <br />
          Soup: Egg Drop, Hot & Sour, or Wonton Soup
          <br />
          Rice: White Rice, Fried Rice, Pork or Chicken Fried Rice
        </div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={HunanChicken}
                alt="Hunan Chicken"
              />
              <p>Hunan Chicken</p>
            </div>
            <div className="foodContainer">
              <img
                className="foodImage"
                src={MongolianBeef}
                alt="Mongolian Beef"
              />
              <p>Mongolian Beef</p>
            </div>
          </div>
          <div className="itemList">
            <MenuMap section="Family Dinner"></MenuMap>
          </div>
        </div>
        <div className="labelDivider">
          <div className="label-line" />
          <div className="labelText">Side Order</div>
          <div className="label-line" />
        </div>
        <div className="menuTextDesciption"></div>
        <br />
        <div className="columnMenu">
          <div className="menuImages">
            <div className="foodContainer">
              <img
                className="foodImage"
                src={WontonChips}
                alt="WontonChips"
              />
              <p>Wonton Chips</p>
            </div>      
          </div>
          <div className="itemList">
            <MenuMap section="Side Order"></MenuMap>
          </div>
        </div>
        <br />
        <br />
        <p className="spicyCenter">🌶️ indicates Hot and Spicy</p>
        <br />
      </div>
    </>
  );
};

export default Menu;
