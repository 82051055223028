import React from "react";
import "./FeaturedDish.css";
import featuredFood1 from './HomeSection/assets/img19.jpg';
import featuredFood2 from './HomeSection/assets/img23.jpg';
import featuredFood3 from './HomeSection/assets/img22.jpg';

import SectionHeader from "./SectionHeader/SectionHeader";

const FeaturedDish = () => {
  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css?family=Bahiana"
        rel="stylesheet"
      />
      <SectionHeader name={"Featured Dishes"} />
      <div className="grid-food">
        <div className="grid-item">
          Hong Kong Chef's Special
          <br />
          (See menu for details!)
        </div>
        <div className="grid-item-pic-container">
          <img
            src={featuredFood1}
            className="grid-item-pic"
            alt="chinese food 1"
          />
        </div>
        <div className="grid-item">
          Special Family Dinner
          <br />
          (See menu for details!)
        </div>
        <div className="grid-item-pic-container">
          <img
            src={featuredFood2}
            className="grid-item-pic"
            alt="chinese food 2"
          />
        </div>
        <div />
        <div className="grid-item">
          Lunch Special
          <br />
          (See menu for details!)
        </div>
        <div className="grid-item-pic-container">
          <img
            src={featuredFood3}
            className="grid-item-pic"
            alt="chinese food 3"
          />
        </div>
        <div />
      </div>
    </div>
  );
};
export default FeaturedDish;
