// ContactUs.js

import React, { useRef } from 'react';
import './ContactUs.css';
import Header from '../Header/Header';
import changeBody from '../../changeBody.js'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactUs({address, phoneNumber, email}) {
    changeBody({color: "#ffffff"})

    emailjs.init({
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    });

    const form = useRef();
    const captchaRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        const captchaValue = captchaRef.current.getValue();
        captchaRef.current.reset();

        if (!captchaValue) {
            alert("Please verify the reCAPTCHA!");
        } else {
            const params = {
                "user_name": form.current.user_name.value,
                "user_email": form.current.user_email.value,
                "subject": form.current.subject.value,
                "message": form.current.message.value,
                "g-recaptcha-response": captchaValue
            }
            
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, params)
                .then(
                    () => {
                        console.log('SUCCESS!');
                    },
                    (error) => {
                        console.log('FAILED', error.text);
                    },
                );

            alert("Form submission successful!");
            form.current.reset();
        }
    };

    return (
    <div>
        <Header/>
        <div style={{height:'50%'}}>
            <div className="contact-us">
            <div className="title-container">
                <hr className="line" />
                <h1 className="title">CONTACT US</h1>
                <hr className="line" />
            </div>
            <div className="contact-info">
                <div className="info">
                    <ul>
                        <div className="info-group">
                            <li><h2>ADDRESS</h2></li>
                            <li style={{fontFamily:'sans-serif'}}>{address}</li>
                        </div>
                        <div className="info-group">
                            <li><h2>PHONE NUMBER</h2></li>
                            <li style={{fontFamily:'sans-serif'}}>{phoneNumber}</li>
                        </div>
                        <div className="info-group">
                            <li><h2>EMAIL</h2></li>
                            <li style={{fontFamily:'sans-serif'}}>{email}</li>
                        </div>
                    </ul>
                </div>
                <div className="message-form">
                <form ref={form} onSubmit={sendEmail}>
                    <div className='personal-details'>
                        <div className="form-group">
                            <input type="text" name="user_name" placeholder='Your Name' required/>
                        </div>
                        <div className="form-group">
                            <input type="email" name="user_email" placeholder='Your Email' required/>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" name="subject" placeholder='Subject' required/>
                    </div>
                    <div className="form-group">
                        <textarea rows="6" name="message" placeholder='Message' required></textarea>
                    </div>
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef}/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="submit-button">SEND MESSAGE</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
}

export default ContactUs;
