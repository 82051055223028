import MenuItems from "./MenuItems.json";

import "./MenuMap.css";

function MenuMap(props) {
  const { section } = props;

  if (!MenuItems[section]) {
    return <div>Section not found</div>;
  }

  return (
    <>
      {MenuItems[section].map((item, i) => (
        <div className="menuItem">
          <div className="menuFood">
            {item.spicy ? (
              <p>
                {item.item}
                <span> 🌶️</span>
              </p>
            ) : (
              <p>{item.item}</p>
            )}
            {item.subtitle ? (
              <p className="menuSubtitle">{item.subtitle}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="menuPrice">
            {typeof item.price === "object" ? (
              Object.entries(item.price).map(([size, price], index) => (
                <span key={index}>
                  {size}: {price}
                  <br />
                </span>
              ))
            ) : (
              <span>{item.price}</span>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default MenuMap;
