import "./SectionHeader.css";

const SectionHeader = ({ name }) => {
  return (
    <>
      <div className="section-header">

            <hr />
            <p>{name}</p>
            <hr />
      
      </div>
    </>
  );
};

export default SectionHeader;
