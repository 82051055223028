import { React, useEffect }from 'react';
import './HomeSection.css';
import images from './images.js';

const HomeSection = () => {

  // Preload images
  useEffect(() => {
    images.forEach(image => {
      const preloadImage = new Image();
      preloadImage.src = image;
      window[image] = preloadImage;
    });
  }, []);

  let imgNum = Math.floor(Math.random() * (21 - 1) + 1);
  setInterval(() => {
    imgNum = Math.floor(Math.random() * (21 - 1) + 1);
    const homeBackground = document.querySelector('#home-background')
    
    if (homeBackground) {
      homeBackground.style.backgroundImage = `url(${images[imgNum]}`;
    }
  }, 7500);

  return (
    <div id='home'>
      <link href="https://fonts.googleapis.com/css?family=M+PLUS+1p" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Bahiana" rel="stylesheet" />      

      <div id='home-container'>
        <div id='home-background' style={{ backgroundImage: `url(${images[imgNum]}` }}>
          <div id='home-overlay'></div>
          <p>Simplicity is complex.</p>
        </div>
      </div>

    </div>
  );
};

export default HomeSection;
