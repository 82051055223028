import React from 'react';
import './Header.css';
import logo from './assets/hong-kong-kitchen-logo-2.jpg';
import {useLocation, Link} from 'react-router-dom'

var hrefEx = "";

const Header = () => {

  const script = document.createElement("script");
  script.src = "https://www.fbgcdn.com/embedder/js/ewm2.js";
  script.async = "true";
  script.defer = "true";

  document.body.appendChild(script);

  const location = useLocation()
  const menuIconToggle = () => {
    document.querySelector('#menu-icon').classList.toggle("change");
    document.querySelector('#mobile-nav').classList.toggle("nav-change")    
  }
  return (
    
    <header>
      <link href="https://fonts.googleapis.com/css?family=M+PLUS+1p" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Bahiana" rel="stylesheet" />
      <div id="top-bar">
        <p>Mon-Sat: 11:00am-8:30pm • Sun: 11:00am-8:00pm</p>
        <a href="https://maps.app.goo.gl/XvJy4qN6pfN1anbN6">Google Maps</a>
      </div>

      <div id="mobile-logo-container">
        <img src={logo} alt="logo" id="logo" />
      
        <div id="menu-icon" onClick={menuIconToggle}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>

      </div>

      <nav id="mobile-nav">
        <div className="mobile-nav-links">
          <a href={hrefEx +"/"} onClick={menuIconToggle}>Home</a>
          {location.pathname === "/" && <a href="#about" onClick={menuIconToggle}>About</a>}
          {location.pathname === "/" && <a href="#reviews" onClick={menuIconToggle}>Reviews</a>}
          <Link to={"/menu"}>Menu</Link>
          <a href="javascript:;"
            data-glf-cuid="47cefa22-e51d-4ead-87c8-8339746be9a7"
            data-glf-ruid="cfe34924-876e-4fc8-bc5a-2392eef2ce35">
            Order Online</a>
            <Link to={"/contact"}>Contact</Link>
        </div>
      </nav>

      <nav id="nav-bar">

        <ul className="nav-links">
          <li><a href={hrefEx + "/"}>Home</a></li>
          {location.pathname === "/" && <li><a href="#about">About</a></li>}
          {location.pathname === "/" && <li><a href="#reviews">Reviews</a></li>}
        </ul>

        <div id="desktop-logo-container">
          <img src={logo} alt="logo" id="logo" />
        </div>

        <ul className="nav-links">
          <li><Link to={"/menu"}>Menu</Link></li>
  
          <li><a id="glfButton1" href="javascript:;"
            data-glf-cuid="47cefa22-e51d-4ead-87c8-8339746be9a7"
            data-glf-ruid="cfe34924-876e-4fc8-bc5a-2392eef2ce35">
            Order Online</a></li>
            
          <li><Link to={"/contact"}>Contact</Link></li>
        </ul>

      </nav>

    </header>
  );
};

export default Header;
