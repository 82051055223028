import React from "react";
import "./Footer.css"; // You will create this CSS file to style the Footer component.

const Footer = ({ address, email, phoneNumber }) => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-part">
          <strong>Address:</strong> {address}
        </div>
        <div className="footer-part">
          <strong>Email:</strong> {email}
        </div>
        <div className="footer-part">
          <strong>Phone:</strong> {phoneNumber}
        </div>
      </div>
    </div>
  );
};

export default Footer;
