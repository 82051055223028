import './Reviews.css'
import ReviewText1 from './ReviewTextImages/ReviewText1';
import ReviewText2 from './ReviewTextImages/ReviewText2';
import ReviewText3 from './ReviewTextImages/ReviewText3';
import ReviewText4 from './ReviewTextImages/ReviewText4';
import ReviewText5 from './ReviewTextImages/ReviewText5';
import fiveStars from './ReviewTextImages/stars.png'
import SectionHeader from './SectionHeader/SectionHeader';

const Reviews = () => {
    return <div id='reviews'>
        <SectionHeader name={"Reviews"} />
        <div id="backgroundMarbel">
            <div id="ReviewTitle">Our Customers Say</div>
            <div id="ReviewTexts">
                <div className="spacingReview">
                    <div><ReviewText1></ReviewText1></div>
                    <div><img src={fiveStars} width={150} height={50} className="bmr" alt="" /></div>
                </div>
                <div className="spacingReview" id="spacingReview2">
                    <div><ReviewText2></ReviewText2></div>
                    <div><img src={fiveStars} width={150} height={50} className="bmr" alt="" /></div>
                </div>
                <div className="spacingReview">
                    <div><ReviewText3></ReviewText3></div>
                    <div><img src={fiveStars} width={150} height={50} className="bmr" alt="" /></div>
                </div>
                <div className="spacingReview">
                    <div><ReviewText4></ReviewText4></div>
                    <div><img src={fiveStars} width={150} height={50} className="bmr" alt="" /></div>
                </div>
                <div className="spacingReview">
                    <div><ReviewText5></ReviewText5></div>
                    <div><img src={fiveStars} width={150} height={50} className="bmr" alt="" /></div>
                </div>
            </div>
        </div>
    </div>;
}

export default Reviews;
