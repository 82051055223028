import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home.js";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer.jsx";
import Menu from "./components/Menu/Menu"

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/contact"
            element={
              <ContactUs
                address="Chandler Heights Village, 3076 E Chandler Heights Rd, Suite 104, Gilbert, AZ 85298"
                email="info@hongkongkitchenaz.com"
                phoneNumber="480 - 507 - 3885"
              />
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />}/>
        </Routes>
      </Router>
      <Footer
        address="Chandler Heights Village, 3076 E Chandler Heights Rd, Suite 104, Gilbert, AZ 85298"
        email="info@hongkongkitchenaz.com"
        phoneNumber="480 - 507 - 3885"
      />
    </>
  );
}

export default App;
