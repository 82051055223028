import React from "react";
import "./About.css";
import food1 from './ReviewTextImages/frontRest_W_Border_Up_Y.webp';
import food2 from './ReviewTextImages/insideRest_W_Border_Up_Y.webp';
import SectionHeader from "./SectionHeader/SectionHeader";

const About = () => {
  return (
    <div id="about">
      <link
        href="https://fonts.googleapis.com/css?family=Bahiana"
        rel="stylesheet"
      />
      <SectionHeader name={"About"} />
      <div className="top-container">
        <div className="top-background">
          <div className="top-overlay"></div>
          <p>
            Welcome to the best Chinese restaurant in Gilbert! This restaurant
            is renowned for its authentic Chinese cuisine and exceptional dining
            experience. From classic dishes like Kung Pao chicken and hot and
            sour soup to more unique options like Szechuan-style spicy fish,
            every dish is made with the freshest ingredients and expertly
            prepared by skilled chefs. The atmosphere is elegant and welcoming,
            with friendly staff always ready to provide excellent service.
            Whether you're craving a quick lunch or a memorable dinner with
            family and friends, this Chinese restaurant is the perfect
            destination for anyone looking for delicious food and a delightful
            dining experience. We serve authentic dishes without a drop of MSG
            in any of our dishes using the freshest ingredients in Gilbert, AZ.
          </p>
        </div>
      </div>
      <div className="bot-container">
        <div className="bot-text">
          <div className="left-text">
            <hr />
            <p>Premium Meat</p>
            <hr />
            <p>Fresh Vegetable</p>
          </div>
          <div className="right-text">
            <hr />
            <p>Fast Service</p>
            <hr />
            <p>Experienced Chef</p>
            <hr />
          </div>
        </div>
        <div className="bot-img">
          <img
            src={food1}
            alt="chinese food dish"
          />
          <img
            src={food2}
            alt="chinese food takeout"
          />
        </div>
      </div>
      <div className="circle-container">
        <div className="outside-circle">
          <div className="center-circle">
            <div className="inner-circle">
              <div className="circle-text">
                <h2>Around for</h2>
                <h1>30</h1>
                <h2>Years</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
