import "./App.css";
import Header from "./components/Header/Header";
import HomeSection from "./components/HomeSection/HomeSection";
import FeaturedDish from "./components/FeaturedDish";
import About from "./components/About";
import Reviews from "./components/Reviews";
import changeBody from './changeBody'

function Home() {
  changeBody({color: "#C9C1BB"})
  return (
    <div className="App">    
      <Header/>
      <HomeSection/>
      <About/>
      <FeaturedDish/>
      <Reviews />
    </div>
  );
}

export default Home;
